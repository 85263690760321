/* eslint-disable eqeqeq , no-useless-escape , react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Payment from "payment";
import {connect, useDispatch} from 'react-redux';
import axios from "axios";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import Button from '@material-ui/core/Button';
import { changeFieldsWithPrefixAndCamelCase, getApiRoot, getContextRoot, getLanguageValue, translateCardDeclineReason, googleRecaptcha, getCountriesList } from "../../common/functions";
import Grid from '@material-ui/core/Grid';
import lodashGet from 'lodash/get';
import GenericFieldComponent from '../GenericFieldComponent';
import CONSTANTS from '../../common/constants';
import { CSS_COLORS } from '../../common/cssColors';
import { ACTIONS } from '../../redux/actions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// or
import {Elements, CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Loader from "../Loader";
import {emptyValueValidator, numericValueValidator, stringValueValidator} from "../../common/validators";
import { reportPurchase } from "../../common/googleReporter";

let stripePromise;
let stripePromiseInterval = setInterval(() => {
    if (CONSTANTS.STRIPE_KEY != null) {
        clearInterval(stripePromiseInterval);
        stripePromise = loadStripe(CONSTANTS.STRIPE_KEY);
    }
}, 100);

let PAYPAL_BUTTONS_REF = null;
let PAYPAL_ACTIONS_REF;
let GLOBAL_VALIDATE_REF = null;
let GLOBAL_DISPATCH_REF = null;
let GLOBAL_LOADERS_REF = null;
let GLOBAL_SET_LOADERS_REF = null;
let GLOBAL_SET_ERROR_MESSAGE = null;

function RegisterStagePayComponent(props) {
    const dispatch = useDispatch();
    const {
        actions
    } = props;

    useEffect(() => {
        actions.setActiveStage(7);
    }, []);

    useEffect(() => {
        // window.addEventListener('popstate', onBackButtonEvent);
        // return () => {
        //     window.removeEventListener('popstate', onBackButtonEvent);
        // }
        // return history.listen(location => {
        //     if (history.action === 'PUSH') {
        //         console.log('PUSH', location.key);
        //         setLocationKeys([ location.key ]);
        //     }
        //
        //     if (history.action === 'POP') {
        //         if (locationKeys[1] === location.key) {
        //             setLocationKeys(([ _, ...keys ]) => keys);
        //             console.log('POP forward');
        //             // Handle forward event
        //
        //         } else {
        //             setLocationKeys((keys) => [ location.key, ...keys ]);
        //             console.log('POP back');
        //             // Handle back event
        //
        //         }
        //     }
        // })
    }, []);

    if (!props.individualKey) {
        return null;
    }

    return <Elements stripe={stripePromise}>
            <SubComponent individualKey={props.individualKey} languageKey={props.languageKey} />
        </Elements>;

        function getPrice(productType) {
            const prices = CONSTANTS.PRICES;
            console.log(props.stage2Data)
            switch (productType) {
                case 'visaType':
                    switch (lodashGet(props.stage2Data, 'visaType')) {
                        case '30days-single':
                            return prices['30days_single'];
                        case '30days-multi':
                            return prices['30days_multi'];
                        case '90days-single':
                            return prices['90days_single'];
                        case '90days-multi':
                            return prices['90days_multi'];
                    }
                    break;
                case 'expedite':
                    if (lodashGet(props.stage2Data, 'visaExpeditedFlag')) {
                        return prices['expedite'];
                    }
                    return 0;
                case 'deliveryType':
                    switch (lodashGet(props.stage2Data, 'deliveryType')) {
                        case 'messenger-d-self-p':
                            return parseFloat(CONSTANTS?.PRICES?.delivery.find((item) => item.key == lodashGet(props.stage2Data, 'deliveryDestination'))?.value);
                        case 'messenger-d-messenger-p':
                            return parseFloat(CONSTANTS?.PRICES?.delivery.find((item) => item.key == lodashGet(props.stage2Data, 'deliveryDestination'))?.value) * 2;
                        case 'self-d-self-p':
                        default:
                            return 0;
                    }
            }
            return 0;
        }
        function getTotalPrice() {
            return (getPrice('visaType') + getPrice('expedite') + getPrice('deliveryType')) * getApplicationsCount();
        }

        function getEmail() {
            return lodashGet(props.stage2Data, 'email');
        }

        function getApplicationsCount() {
            return 1;
        }
    function SubComponent(props) {

        const [ paymentType, setPaymentType ] = useState('card');

        const [ fieldsData, setFieldsData ] = useState({
            fullName: '',
            addressLine1: '',
            addressLine2: '',
            country: props.languageKey != 'he' ? 'select' : 'Israel',
            state: '',
            city: '',
            zipCode: '',
            // addressSameAsBilling: false,
            // billingAddressLine1: '',
            // billingAddressLine2: '',
            // billingAddressCountry: 'Israel',
            // billingAddressState: '',
            // billingAddressCity: '',
            // billingAddressZipCode: ''
        });
        const [ payButtonDisabled, setPayButtonDisabled ] = useState(false);
        let [ fieldErrors, setFieldErrors ] = useState({ });

        const FIELDS_VALIDATORS = [
            {
                key: 'fullName',
                validators: [
                    {
                        test: emptyValueValidator,
                        errorMessage: getLanguageValue('register-field-errors-empty')
                    },
                    {
                        test: stringValueValidator.bind({ languageKey: 'all' }),
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            {
                key: 'addressLine1',
                validators: [
                    {
                        test: emptyValueValidator,
                        errorMessage: getLanguageValue('register-field-errors-empty')
                    },
                    {
                        test: stringValueValidator.bind({ languageKey: 'allAndNumeric' }),
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            {
                key: 'addressLine2',
                validators: [
                    {
                        test: (value) => {
                            if (value.length) {
                                if (!/^([\u0590-\u05FF\"\'\.\-,\s0-9]+)$/g.test(value) && !/^([a-zA-Z\"\'\.\-,\s0-9]+)$/g.test(value)) {
                                    return false;
                                }
                            }
                            return true;
                        },
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            {
                key: 'city',
                validators: [
                    {
                        test: emptyValueValidator,
                        errorMessage: getLanguageValue('register-field-errors-empty')
                    },
                    {
                        test: stringValueValidator.bind({ languageKey: 'all' }),
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            {
                key: 'state',
                validators: [
                    {
                        test: (value) => { if (value.length) { if (!/^([\u0590-\u05FF\s]+)$/g.test(value) && !/^([a-zA-Z\s]+)$/g.test(value)) return false; } return true; },
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            {
                key: 'country',
                validators: [
                    {
                        test: emptyValueValidator,
                        errorMessage: getLanguageValue('register-field-errors-empty')
                    },
                    {
                        test: stringValueValidator.bind({ languageKey: 'all' }),
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            {
                key: 'zipCode',
                validators: [
                    {
                        test: emptyValueValidator,
                        errorMessage: getLanguageValue('register-field-errors-empty')
                    },
                    {
                        test: (value) => { return stringValueValidator.bind({ languageKey: 'enAndNumeric' })(value); },
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            // {
            //     key: 'billingAddressLine1',
            //     validators: [
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : emptyValueValidator,
            //             errorMessage: getLanguageValue('register-field-errors-empty')
            //         },
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : stringValueValidator.bind({ languageKey: 'all' }),
            //             errorMessage: getLanguageValue('register-field-errors-invalid')
            //         }
            //     ]
            // },
            // {
            //     key: 'billingAddressLine2',
            //     validators: [
            //         {
            //             test: fieldsData.addressSameAsBilling ?
            //                 returnTrue :
            //                 (value) => {
            //                     if (value.length) {
            //                         if (!/^([\u0590-\u05FF\s0-9]+)$/g.test(value) || !/^([a-zA-Z\s0-9]+)$/g.test(value)) {
            //                             return false;
            //                         }
            //                     }
            //                     return true;
            //             },
            //             errorMessage: getLanguageValue('register-field-errors-invalid')
            //         }
            //     ]
            // },
            // {
            //     key: 'billingAddressCity',
            //     validators: [
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : emptyValueValidator,
            //             errorMessage: getLanguageValue('register-field-errors-empty')
            //         },
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : stringValueValidator.bind({ languageKey: 'all' }),
            //             errorMessage: getLanguageValue('register-field-errors-invalid')
            //         }
            //     ]
            // },
            // {
            //     key: 'billingAddressCountry',
            //     validators: [
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : emptyValueValidator,
            //             errorMessage: getLanguageValue('register-field-errors-empty')
            //         },
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : stringValueValidator.bind({ languageKey: 'all' }),
            //             errorMessage: getLanguageValue('register-field-errors-invalid')
            //         }
            //     ]
            // },
            // {
            //     key: 'billingAddressState',
            //     validators: [
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : emptyValueValidator,
            //             errorMessage: getLanguageValue('register-field-errors-empty')
            //         },
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : stringValueValidator.bind({ languageKey: 'all' }),
            //             errorMessage: getLanguageValue('register-field-errors-invalid')
            //         }
            //     ]
            // },
            // {
            //     key: 'billingAddressZipCode',
            //     validators: [
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : emptyValueValidator,
            //             errorMessage: getLanguageValue('register-field-errors-empty')
            //         },
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : numericValueValidator,
            //             errorMessage: getLanguageValue('register-field-errors-invalid')
            //         }
            //     ]
            // }
        ];
        let [ cardFieldError, setCardFieldError ] = useState({ });
        const [ cardComplete, setCardComplete ] = useState({ });
        const [checked, setChecked] = React.useState(false);
        const [checked1, setChecked1] = React.useState(false);
        const [ errorMessage, setErrorMessage] = useState('');
        const [ errorMessage1, setErrorMessage1] = useState('');
        const [ loaders, setLoaders ] = useState({ });
        const handleNumber = (evt) => {

          evt.target.value = formatCreditCardNumber( evt.target.value);
          var valid = require("card-validator");
          if (!evt.target.value)
              setErrorMessage1(getLanguageValue('credit-card-card-number_not-valid'))
          else {

          }
          var numberValidation = valid.number(evt.target.value);

          //console.log(numberValidation)

          if (!numberValidation.isValid) {
              setErrorMessage1(getLanguageValue('credit-card-card-number-not-valid'))

           }
            else {

                    setErrorMessage1('')
                     // zenValidation()
            }


        }

        const handleCVC = (evt) => {

          evt.target.value = formatCVC( evt.target.value);
          if (!evt.target.value)
              setIsPayButtonZen(true)
          else {

          }

          if (evt.target.value.length<3 && evt.target.value!="")
                setErrorMessage1(getLanguageValue('credit-card-cvc-is-invalid'))
          else {
                setErrorMessage1('')
              //  zenValidation()

          }

        }


        const handleExpiry = (evt) => {

          evt.target.value = formatExpirationDate( evt.target.value);
          if (!evt.target.value)
              setIsPayButtonZen(true)
              else {

              }

              if (evt.target.value.length<5 && evt.target.value!="")
                    setErrorMessage1(getLanguageValue('credit-card-expiration-is-invalid'))
              else {
                  setErrorMessage1('')
                  //  zenValidation()
                }


        }


        const handleInputChange = (evt) => {

          const handleName = (evt) => {

            var update=true
            const regex = /^[a-z ]+$/i;
            if (!evt.target.value)
                setIsPayButtonZen(true)
                else
                     zenValidation()

            if ((evt.target.value.length>=0 && evt.target.value.length<=40))
            {

            }
            else {
              update=false
              evt.target.value=evt.target.value.slice(0,-1)
              //alert(evt.target.value)
            }



            if ((evt.target.value === "" || regex.test(evt.target.value))) {


            }
            else {
              update=false
              evt.target.value=evt.target.value.slice(0,-1)
              //alert(evt.target.value)
            }

            if ((evt.target.value.length<5))
            {


                setErrorMessage1(getLanguageValue('credit-card-card-owner-issue'))
            }
            else {

                setErrorMessage1('')
            }

           return update;


          }


         var update=true
          if (evt.target.name === "number") {
               handleNumber(evt)
                 zenValidation(evt)

          } else if (evt.target.name === "expiry") {
               handleExpiry(evt)
                 zenValidation(evt)

          } else if (evt.target.name === "cvc") {
               handleCVC(evt)
               zenValidation(evt)
          }
          else if (evt.target.name === "name") {

             update = handleName (evt)
             zenValidation(evt)

          }






         const { name, value } = evt.target;

         if (update)
         {
             setState((prev) => ({ ...prev, [name]: value }));


         }








       }
         const [paymentTry, setPaymentTry] = useState(1);
        const zenValidation = (evt) => {

          if (evt)
          {
            if (evt.target.name=="name")
                  state['name'] = evt.target.value
                  if (evt.target.name=="number")
                        state['number'] = evt.target.value
                        if (evt.target.name=="cvc")
                              state['cvc'] = evt.target.value
                              if (evt.target.name=="expiry")
                                    state['expiry'] = evt.target.value

          }



          if (state['number'] && state['expiry'] && state['cvc'] &&state['name']  )
          {
            setIsPayButtonZen(false)
          }
          else {
            setIsPayButtonZen(true)
          }
          var valid = require("card-validator");
          var numberValidation = valid.number(state['number']);

          if (!state['number'])
          {
              setErrorMessage1(getLanguageValue('credit-card-card-number-not-valid'))
              return;
          }
          else if (!numberValidation.isValid) {
              setErrorMessage1(getLanguageValue('credit-card-card-number-not-valid'))
              return;

          }
          else if (state['cvc'].length<3 && state['cvc']!="")
          {
                setErrorMessage1(getLanguageValue('credit-card-cvc-is-invalid'))
                return;
          }
          else if (state['name'].length<5 && state['name']!="")
          {
                setErrorMessage1(getLanguageValue('credit-card-card-owner-issue'))
                return;
          }
          else if (state['expiry'].length<5 && state['expiry']!="")
          {
                setErrorMessage1(getLanguageValue('credit-card-expiration-is-invalid'))
                return;
          }
          else {
            setChecked1(true)
                setErrorMessage1('')
          }


        }

         const postRequestZen = (path, data) => {

          var token="SSp@LHp*$bp6kL"
          axios.defaults.headers.common['Authorization'] = `Bearer `+token;
          axios.defaults.headers.common['x-access-token'] = token;
          const headers_post= {
                        headers: {
                        'Authorization': `Basic ${token}`
                        }
                        }


          var result=axios.post(`https://govisa.ai:8880/api/govisai/getGOVISPAYMENTINFO`, {}, {
            headers: {
              "Content-Type": "application/json",
            },
          }).then((resp) => {

            console.log((resp.data.payment_id))
            console.log((resp.data.payment_port))

            return axios.post(`${"https://govisa.ai:"}${resp.data.payment_port}${path}`, data, {
              headers: {
                "Content-Type": "application/json",
              },
            }).then((resp) => {

                console.log((resp))

                if (resp.data.errorCode||resp.data.code)
                   if (resp.data.errorCode>1 || resp.data.code!="" )
                   {

                     if (typeof resp.data.errorText !== 'undefined' && resp.data.errorText !== null){
                        setErrorMessage(resp.data.errorText)
                        }

                        if (typeof resp.data.text !== 'undefined' && resp.data.text !== null){
                           setErrorMessage(resp.data.text)
                           }

                        setPaymentTry(paymentTry+1)
                        if (paymentTry>=1)
                        {
                          //setIsLoading(false)
                          alert('Payment Failed. Please try again later with the direct link in the email you received.')
                          setIsLoading1(true)
                          window.location.reload(true)
                        }
                        setIsPayButtonZen(false)
                       return 1;
                   }



                       if(resp.data.responseCode!="1")
                              return 1;

                       var clientSecret=resp.data.transId

                       dispatch({
                           type: ACTIONS.ROUTER_SET_REDIRECT,
                           payload: '/register-visa-for-individual/stageSuccess'
                       });


            });


          });




        };
         const [isLoading1, setIsLoading1] = useState(true);
         const handleInputFocus = (evt) => {
           setState((prev) => ({ ...prev, focus: evt.target.name }));

           zenValidation()

         }
        const [state, setState] = useState({
          number: '',
          expiry: '',
          cvc: '',
          name: '',
          focus: '',
        });
        const [isPayButtonZen, setIsPayButtonZen] = useState(false);



        const stripe = useStripe();
        const elements = useElements();

        function handleZenSubmit() {


            //zenValidation()

            setErrorMessage1('')
            setIsLoading1(true);
            setIsPayButtonZen(true)





            const data ={}


              data.amount= getTotalPrice()
              data.form_id=props.individualKey


              data.statement_descriptor_suffix=  window.location.hostname;
              data.revealprice=  false;
              data.email=  getEmail()
              data.response_id=  "1"

              data.number=state['number']
              data.expiry=state['expiry']
               data.cvc=state['cvc']
               data.name=state['name']
               console.log(data)





        //var customer ={}
        //customer.email=submitData.result.email
        //customer.name=submitData.result.first_name+" "+submitData.result.last_name



             postRequestZen(`/payment/create`, data);





          }

        GLOBAL_VALIDATE_REF = validate;
        GLOBAL_DISPATCH_REF = dispatch;
        GLOBAL_LOADERS_REF = loaders;
        GLOBAL_SET_LOADERS_REF = setLoaders;
        GLOBAL_SET_ERROR_MESSAGE = setErrorMessage;

        useEffect(() => {
            window.routeChangeTimestamp = new Date().getTime();
            return () => {
                if (window.paypal) {
                    if (PAYPAL_BUTTONS_REF) {
                        if (PAYPAL_BUTTONS_REF.close) {
                            PAYPAL_BUTTONS_REF.close();
                        }
                        PAYPAL_BUTTONS_REF = null;
                    }
                }
            };
        }, []);

        useEffect(() => {

              setChecked1(false)
              setIsLoading1(false);
            setTimeout(() => {
                if (window.paypal) {
                    if (PAYPAL_BUTTONS_REF) {
                        if (PAYPAL_BUTTONS_REF.close) {
                            PAYPAL_BUTTONS_REF.close();
                        }
                        PAYPAL_BUTTONS_REF = null;
                    }
                }
            }, 10);

            if (stripe && elements && window.paypal) {
                //!PAYPAL_INITIALIZED_FLAG
                //PAYPAL_INITIALIZED_FLAG = true;
                setTimeout(() => {

                    PAYPAL_BUTTONS_REF = window.paypal.Buttons({
                        onInit: function(data, actions) {
                            PAYPAL_ACTIONS_REF = actions;
                            actions.disable();
                        },
                        onClick: function() {
                            GLOBAL_VALIDATE_REF({ setErrors: true });
                        },
                        createOrder: async function() {
                            let duplicateData = { ...fieldsData };
                            //duplicateData.addressSameAsBilling = duplicateData.addressSameAsBilling ? '1' : '0';
                            GLOBAL_SET_LOADERS_REF({ ...GLOBAL_LOADERS_REF, paypal: true });
                            let recaptchaToken = await googleRecaptcha();
                            return fetch(getApiRoot() + 'init-payment/?type=individual&paymentType=paypal&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp), {
                                method: 'post',
                                headers: {
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify({
                                    recaptchaToken,
                                    key: props.individualKey,
                                    ...changeFieldsWithPrefixAndCamelCase(duplicateData, 'stagePay')
                                })
                            }).then(function(res) {
                                return res.json();
                            }).then(function(data) {
                                return data.data.clientSecret;
                            }).catch(function() {
                                GLOBAL_SET_LOADERS_REF({ ...GLOBAL_LOADERS_REF, paypal: false });
                                GLOBAL_SET_ERROR_MESSAGE(getLanguageValue('register-card-errors-network-error'));
                            });
                        },
                        onCancel: function() {
                            GLOBAL_SET_LOADERS_REF({ ...GLOBAL_LOADERS_REF, paypal: false });
                        },
                        onApprove: async function() {
                            let recaptchaToken = await googleRecaptcha();
                            return fetch(getApiRoot() + 'complete-payment/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp), {
                                method: 'POST',
                                body: JSON.stringify({
                                    recaptchaToken,
                                    key: props.individualKey
                                })
                            }).then(function(res) {
                                return res.json();
                            }).then(function(data) {
                                if (!lodashGet(data, 'data.success')) {
                                    GLOBAL_SET_LOADERS_REF({ ...GLOBAL_LOADERS_REF, paypal: false });
                                    GLOBAL_SET_ERROR_MESSAGE(getLanguageValue('register-card-errors-critical-network-error'));
                                    return;
                                }
                                // paypal payment ok
                                reportPurchase(lodashGet(data, 'data.totalPrice'));
                                GLOBAL_DISPATCH_REF({
                                    type: ACTIONS.ROUTER_SET_REDIRECT,
                                    payload: '/register-visa-for-individual/stageSuccess'
                                });
                            }).catch(function() {
                                GLOBAL_SET_LOADERS_REF({ ...GLOBAL_LOADERS_REF, paypal: false });
                                GLOBAL_SET_ERROR_MESSAGE(getLanguageValue('register-card-errors-critical-network-error'));
                            });
                        },
                        onError: (err) => {
                            GLOBAL_SET_LOADERS_REF({ ...GLOBAL_LOADERS_REF, paypal: false });
                            GLOBAL_SET_ERROR_MESSAGE(getLanguageValue('register-card-errors-critical-network-error'));
                        }
                    });
                    PAYPAL_BUTTONS_REF.render('#paypal-button-container');
                }, 60);
            }
        }, [ stripe, elements ]);

        useEffect(() => {
            if (PAYPAL_ACTIONS_REF) {
                const validationResult = validate();
                if (!validationResult.length) {
                    PAYPAL_ACTIONS_REF.enable();
                }
                else {
                    PAYPAL_ACTIONS_REF.disable();
                }
           }

        }, [ fieldsData, paymentType ]);

        return <RegisterStagePayStyle>
            <div className={"form-container"}>
                <h1>{getLanguageValue('register-individual.stagePay-payment-details')}</h1>
                <Grid container>
                <Grid item md={6} className={"column-container"}>
                    <h2>{getLanguageValue('register-individual.stagePay-billing-address-details')}</h2>
                    <GenericFieldComponent
                        type={"textfield"}
                        fieldProps={{
                            className: 'field-component',
                            error: fieldErrors.fullName,
                            value: fieldsData.fullName,
                            inputProps: {
                                name: 'fullName'
                            },
                            helperText: fieldErrors.fullName || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'fullName' }),
                            label: getLanguageValue('register-individual.stagePay-full-name') + ' *'
                        }}
                    />

                    <br />

                    <GenericFieldComponent
                        type={"textfield"}
                        fieldProps={{
                            className: 'field-component',
                            error: fieldErrors.addressLine1,
                            value: fieldsData.addressLine1,
                            inputProps: {
                                name: 'addressLine1'
                            },
                            helperText: fieldErrors.addressLine1 || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'addressLine1' }),
                            label: getLanguageValue('register-individual.stagePay-address-line1') + ' *'
                        }}
                    />

                    <br />

                    <GenericFieldComponent
                        type={"textfield"}
                        fieldProps={{
                            className: 'field-component',
                            error: fieldErrors.addressLine2,
                            value: fieldsData.addressLine2,
                            inputProps: {
                                name: 'addressLine2'
                            },
                            helperText: fieldErrors.addressLine2 || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'addressLine2' }),
                            label: getLanguageValue('register-individual.stagePay-address-line2')
                        }}
                    />

                    <br />

                    <GenericFieldComponent
                        type={"select"}
                        selectId={'country'}
                        selectLabel={getLanguageValue('register-individual.stagePay-country') + ' *'}
                        selectOptions={[
                            { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                            ...(getCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                        ]}
                        selectFormClassName={'field-component' + (fieldErrors.country ? ' field-error' : '')}
                        fieldProps={{
                            native: true,
                            error: fieldErrors.country,
                            value: fieldsData.country,
                            inputProps: {
                                name: 'country',
                                id: 'country'
                            },
                            helperText: fieldErrors.country || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'country' })
                        }}
                    />

                    <br />

                    <GenericFieldComponent
                        type={"textfield"}
                        fieldProps={{
                            className: 'field-component',
                            error: fieldErrors.state,
                            value: fieldsData.state,
                            inputProps: {
                                name: 'state'
                            },
                            helperText: fieldErrors.state || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'state' }),
                            label: getLanguageValue('register-individual.stagePay-state')
                        }}
                    />

                    <br />

                    <GenericFieldComponent
                        type={"textfield"}
                        fieldProps={{
                            className: 'field-component',
                            error: fieldErrors.city,
                            value: fieldsData.city,
                            inputProps: {
                                name: 'city'
                            },
                            helperText: fieldErrors.city || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'city' }),
                            label: getLanguageValue('register-individual.stagePay-city') + ' *'
                        }}
                    />

                    <br />

                    <GenericFieldComponent
                        type={"textfield"}
                        fieldProps={{
                            className: 'field-component',
                            error: fieldErrors.zipCode,
                            value: fieldsData.zipCode,
                            inputProps: {
                                name: 'zipCode'
                            },
                            helperText: fieldErrors.zipCode || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'zipCode' }),
                            label: getLanguageValue('register-individual.stagePay-zip-code') + ' *'
                        }}
                    />
                </Grid>
                <Grid item md={6} className={"column-container"}>
                    <h2>{getLanguageValue('register-individual.stagePay-billing-payment-details')}</h2>
                    <div className={""}>
                        <div align="right" className={"payment-type-inner-container"}>
                        <center>
                            <RadioGroup aria-label="payment-type" name="paymentTypeRadios" value={paymentType} onChange={(e) => { setPaymentType(e.target.value); }}>
                                {<FormControlLabel
                                    classes={{ root: 'payment-type-control-root-component', label: 'payment-type-label-control-label-component' }}
                                    value="card"
                                    control={<Radio
                                        disabled={isLoading()}
                                        classes={{ root: 'payment-type-card-radio-root' }}
                                        color="primary"
                                    />}

                                    label={<><span><img alt={''} className={"payment-type-image"} src={getContextRoot() + 'images/credit-cards.png'} /></span></>}
                                />}
                                <FormControlLabel
                                    classes={{ root: 'payment-type-control-root-component', label: 'payment-type-label-control-label-component' }}
                                    value="paypal"
                                    control={<Radio
                                        disabled={isLoading()}
                                        classes={{ root: 'payment-type-paypal-radio-root' }}
                                        color="primary"
                                    />}

                                    label={<><span><img alt={''} className={"payment-type-image"} src={getContextRoot() + 'images/credit-cards.png'} /><img alt={''} className={"payment-type-image"} src={getContextRoot() + 'images/paypal.png'} /></span><span className={"payment-type-text paypal"}></span></>}
                                />
                            </RadioGroup>
                            </center>
                        </div>
                    </div>
                    <div className={"" + (paymentType == 'card' ? '' : ' invisible')}>
                    {paymentType == "card" &&    <StyleZenPayment><div key="Payment"> <div> <br/>
                   <div dir="ltr">
                   <Cards
                     number={state.number}
                     expiry={state.expiry}
                     cvc={state.cvc}
                     preview={true}
                     name={state.name}
                     focused={state.focus}
                   />
                   </div>



                   <div>
                   <br/>

                   <div  >
                      <input class="inputStyle" dir={CONSTANTS.MEDIA_BREAKPOINTS_MOBILE=="he"?"ltr":""}
                                type="tel"
                             name="number"

                             placeholder={getLanguageValue('credit-card-card-number')}
                             pattern="[\d| ]{16,22}"
                             required
                             value={state['number']}
                             onChange={handleInputChange}
                             onFocus={handleInputFocus}
                      />

                    </div>
                    <br/>

                    <div >
                      <input class="inputStyle" dir={CONSTANTS.MEDIA_BREAKPOINTS_MOBILE=="he"?"ltr":""}
                        type="text"
                        name="name"

                         placeholder={getLanguageValue('credit-card-card-owner')}
                        required
                        value={state['name']}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                      />
                    </div>
                    <br/>

                    <div>
                        <input class="inputStyle" dir={CONSTANTS.MEDIA_BREAKPOINTS_MOBILE=="he"?"ltr":""}
                          type="tel"
                          name="expiry"

                           placeholder={getLanguageValue('credit-card-card-expiration')}
                          pattern="\d\d/\d\d"
                          required
                          value={state['expiry']}
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                        />
                      </div>
                      <br/>

                      <div  >
                        <input class="inputStyle" dir={CONSTANTS.MEDIA_BREAKPOINTS_MOBILE=="he"?"ltr":""}
                          type="tel"
                          name="cvc"

                            placeholder={getLanguageValue('credit-card-card-cvc')}
                          pattern="\d{3,4}"
                          required
                          value={state['cvc']}
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                        />
                      </div>
                      <br/>
                      { errorMessage1 && <Grid item xs={12} md={12} sx={{mt:2}}>
                          <Typography
                            variant="caption"



                            sx={{
                              mr: 2,
                              //display: { xs: 'flex', md: 'none' },
                              flexGrow: 1,
                              fontFamily: 'monospace',
                              fontWeight: 500,
                              letterSpacing: '.1rem',
                              color: 'red',
                              textDecoration: 'none',
                            }}
                          >
                           {errorMessage1 && <div className={"error-container"}><b>{errorMessage1}</b></div>}
                          </Typography>
                       </Grid>}
                       { !errorMessage1 &&<br/>}

                    <div >
                    <br/>


                     {
                     <Button
                             className={(!checked1 || isLoading1 || isPayButtonZen || errorMessage1!="")? "payStyleDisabled" : "payStyle"}
                            color="success"
                           disabled={!checked1 || isLoading1 || isPayButtonZen || errorMessage1!=""}
                            size="large"
                            type="submit"
                            onClick={(event, newValue) => {

                              //zenValidation()
                              handleZenSubmit()
                                //props.handleSubmit();
                                //setIsLoading(false);
                                //handleClickOpen();




                            }}



                    >
                         {getLanguageValue('credit-card-payment')}
                    </Button>}

                    </div>
                     <br/>

                    </div>

                 </div> </div> </StyleZenPayment>}
                    </div>
                      <div >

                          <br/>
                          { <div hidden={paymentType=="paypal"?"":"hidden"} id="paypal-button-container" />}


                            { loaders.paypal ? <>&nbsp; <Loader className="paypal-loader" /></> : ''}

                    </div>
                    <div className={"billing-address-details-container"} style={{ display: 'none' }}>
                        <FormControlLabel
                            className={"address-same-as-billing-component"}
                            control={
                                <Checkbox
                                    checked={fieldsData.addressSameAsBilling}
                                    onChange={(e) => { toggleAddressSameAsBilling(e); }}
                                    name="addressSameAsContact"
                                    color="primary"
                                />
                            }
                            label={getLanguageValue('register-individual.stagePay-address-same-as-payer-address')}
                        />

                        <br />
                        <GenericFieldComponent
                            type={"textfield"}
                            fieldProps={{
                                className: 'billing-address field-component',
                                error: !fieldsData.addressSameAsBilling && fieldErrors.billingAddressLine1,
                                value: fieldsData.addressSameAsBilling ? fieldsData.addressLine1 : fieldsData.billingAddressLine1,
                                disabled: fieldsData.addressSameAsBilling,
                                inputProps: {
                                    name: 'billingAddressLine1'
                                },
                                helperText: fieldErrors.billingAddressLine1 || '',
                                onChange: DEFAULT_ON_CHANGE,
                                onBlur: validateSingleField.bind({ key: 'billingAddressLine1' }),
                                label: getLanguageValue('register-individual.stagePay-address-line1') + ' *'
                            }}
                        />

                        <GenericFieldComponent
                            type={"textfield"}
                            fieldProps={{
                                className: 'billing-address field-component',
                                error: !fieldsData.addressSameAsBilling && fieldErrors.billingAddressLine2,
                                value: fieldsData.addressSameAsBilling ? fieldsData.addressLine2 : fieldsData.billingAddressLine2,
                                disabled: fieldsData.addressSameAsBilling,
                                inputProps: {
                                    name: 'billingAddressLine2'
                                },
                                helperText: fieldErrors.billingAddressLine2 || '',
                                onChange: DEFAULT_ON_CHANGE,
                                onBlur: validateSingleField.bind({ key: 'billingAddressLine2' }),
                                label: getLanguageValue('register-individual.stagePay-address-line2')
                            }}
                        />

                        <br />

                        <GenericFieldComponent

                            type={"select"}
                            selectId={'billingAddressCountry'}
                            selectLabel={getLanguageValue('register-individual.stagePay-country') + ' *'}
                            selectOptions={[
                                { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                                ...(getCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                            ]}
                            selectFormClassName={'billing-address field-component' + (!fieldsData.addressSameAsBilling && fieldErrors.billingAddressCountry ? ' field-error' : '')}
                            fieldProps={{
                                native: true,
                                error: !fieldsData.addressSameAsBilling && fieldErrors.billingAddressCountry,
                                value: fieldsData.addressSameAsBilling ? fieldsData.country : fieldsData.billingAddressCountry,
                                disabled: true,
                                inputProps: {
              name: 'country',
              id: 'billingAddressCountry',
              style: {
                  fontSize: '0.75em !important',
                  maxWidth: '240px !important ',
                  color: '#737373 !important',
              }
            },

                                helperText: fieldErrors.billingAddressCountry || '',
                                onChange: DEFAULT_ON_CHANGE,
                                onBlur: validateSingleField.bind({ key: (fieldsData.addressSameAsBilling ? 'country' : 'billingAddressCountry') })
                            }}
                        />

                        <GenericFieldComponent
                            type={"textfield"}
                            fieldProps={{
                                className: 'billing-address field-component',
                                error: !fieldsData.addressSameAsBilling && fieldErrors.billingAddressState,
                                value: fieldsData.addressSameAsBilling ? fieldsData.state : fieldsData.billingAddressState,
                                disabled: fieldsData.addressSameAsBilling,
                                inputProps: {
                                    name: 'billingAddressState'
                                },
                                helperText: fieldErrors.billingAddressState || '',
                                onChange: DEFAULT_ON_CHANGE,
                                onBlur: validateSingleField.bind({ key: 'billingAddressState' }),
                                label: getLanguageValue('register-individual.stagePay-state') + ' *'
                            }}
                        />

                        <br />

                        <GenericFieldComponent
                            type={"textfield"}
                            fieldProps={{
                                className: 'billing-address field-component',
                                error: !fieldsData.addressSameAsBilling && fieldErrors.billingAddressCity,
                                value: fieldsData.addressSameAsBilling ? fieldsData.city : fieldsData.billingAddressCity,
                                disabled: fieldsData.addressSameAsBilling,
                                inputProps: {
                                    name: 'billingAddressCity'
                                },
                                helperText: fieldErrors.billingAddressCity || '',
                                onChange: DEFAULT_ON_CHANGE,
                                onBlur: validateSingleField.bind({ key: 'billingAddressCity' }),
                                label: getLanguageValue('register-individual.stagePay-city') + ' *'
                            }}
                        />

                        <GenericFieldComponent
                            type={"textfield"}
                            fieldProps={{
                                className: 'billing-address field-component',
                                error: !fieldsData.addressSameAsBilling && fieldErrors.billingAddressZipCode,
                                value: fieldsData.addressSameAsBilling ? fieldsData.zipCode : fieldsData.billingAddressZipCode,
                                disabled: fieldsData.addressSameAsBilling,
                                inputProps: {
                                    name: 'billingAddressZipCode'
                                },
                                helperText: fieldErrors.billingAddressZipCode || '',
                                onChange: DEFAULT_ON_CHANGE,
                                onBlur: validateSingleField.bind({ key: 'billingAddressZipCode' }),
                                label: getLanguageValue('register-individual.stagePay-zip-code') + ' *'
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
            </div>


          }
            {false && paymentType != 'paypal' && <div className="pay-now-button-container">
                <Button disabled={loaders.pay || payButtonDisabled} className={"pay-now-button common-style-primary-button"} onClick={pay}>{getLanguageValue('register-individual.stagePay-pay-button-text')} {loaders.pay ? <>&nbsp; <Loader className="button-loader" /></> : ''}</Button>
                <div className={"error-container"}>{errorMessage}</div>
            </div>}



            <center>
            <img
            width="300px"
            style={{backgroundColor:`black`}}
            src={`https://govis.ai/images/payment.png`}
            alt={`Paymnet Methods`}
          loading="lazy"
            />
            <br/>
            <img
            width="150px"
            center
            src={`https://govis.ai/images/PayPal.png`}
            alt={`Paymnet Methods`}
          loading="lazy"
            />
            <img
            width="100px"
            center
            src={`https://govis.ai/images/pci.png`}
            alt={`PCI`}
          loading="lazy"
            />
          </center>
        </RegisterStagePayStyle>;

        function DEFAULT_ON_CHANGE(e) {
            setFieldsData({...fieldsData, [e.target.name] : e.target.value });
            setFieldErrors({ ...fieldErrors, [e.target.name] : null });
        }

        function toggleAddressSameAsBilling() {
            fieldsData.addressSameAsBilling = !fieldsData.addressSameAsBilling;
            setFieldsData({ ...fieldsData });
            setFieldErrors({ ...fieldErrors, billingAddressLine1: false, billingAddressLine2: false, billingAddressCity: false, billingAddressState: false, billingAddressCountry: false, billingAddressZipCode: false });
        }

        async function pay() {
            try {
                if (loaders.pay) {
                    return;
                }
                const validateResult = validate({ setErrors: true });
                if (validateResult.length) {
                    setTimeout(function() {
                        if (document.querySelectorAll('.field-error')[0]) {
                            document.querySelectorAll('.field-error')[0].scrollIntoView();
                        }
                    }, 50);
                    return;
                }
                if (!stripe || !elements) {
                    // Stripe.js has not yet loaded.
                    // Make sure to disable form submission until Stripe.js has loaded.
                    return;
                }
                setLoaders({ ...loaders, pay: true });
                let url = getApiRoot() + 'init-payment/?type=individual&paymentType=card&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
                let duplicateData = { ...fieldsData };
                //duplicateData.addressSameAsBilling = duplicateData.addressSameAsBilling ? '1' : '0';
                let recaptchaToken = await googleRecaptcha();
                let options = {
                    ...CONSTANTS.POST_DEFAULT_OPTIONS,
                    body: JSON.stringify({
                        recaptchaToken,
                        key: props.individualKey,
                        ...changeFieldsWithPrefixAndCamelCase(duplicateData, 'stagePay')
                    })
                };
                setErrorMessage('');
                let payApiResult;
                try {
                    payApiResult = await (await fetch(url, options)).json();
                }
                catch (err) {
                    // error on creating payment intent
                    setLoaders({ ...loaders, pay: false });
                    return;
                }
                if (!lodashGet(payApiResult, 'data.clientSecret')) {
                    // error on creating payment intent
                    setErrorMessage(getLanguageValue('register-card-errors-network-error'));
                    setLoaders({ ...loaders, pay: false });
                    return;
                }

                let result = await stripe.confirmCardPayment(lodashGet(payApiResult, 'data.clientSecret'), {
                    payment_method: {
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: fieldsData.fullName,
                        },
                    }
                });

                if (result.error) {
                    let declineReasonText = translateCardDeclineReason(result);
                    setErrorMessage(declineReasonText);
                    setLoaders({ ...loaders, pay: false });
                    return;
                }
                else {
                    if (result.paymentIntent.status === 'succeeded') {
                        let completePaymentResult;
                        try {
                            recaptchaToken = await googleRecaptcha();
                            options = {
                                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                                body: JSON.stringify({
                                    recaptchaToken,
                                    key: props.individualKey
                                })
                            };
                            url = getApiRoot() + 'complete-payment/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
                            completePaymentResult = await (await fetch(url, options)).json();
                            if (!lodashGet(completePaymentResult, 'data.success')) {
                                setLoaders({ ...loaders, pay: false });
                                setErrorMessage(getLanguageValue('register-card-errors-critical-network-error'));
                                setPayButtonDisabled(true);
                                return;
                            }
                        }
                        catch (err) {
                            // nothing to do
                            setLoaders({ ...loaders, pay: false });
                            setErrorMessage(getLanguageValue('register-card-errors-critical-network-error'));
                            setPayButtonDisabled(true);
                            return;
                        }
                        // visa payment ok
                        reportPurchase(lodashGet(completePaymentResult, 'data.totalPrice'));
                        dispatch({
                            type: ACTIONS.ROUTER_SET_REDIRECT,
                            payload: '/register-visa-for-individual/stageSuccess'
                        });
                    }
                    else {
                        // unknown error
                        setErrorMessage(getLanguageValue('register-card-errors-invalid-generic'));
                    }
                }
                setLoaders({ ...loaders, pay: false });
            }
            catch (err) {
                setLoaders({ ...loaders, pay: false });
            }
        }

        function validate(config) {
            let output = [];
            if (lodashGet(config, 'setErrors')) {
                fieldErrors = {};
                cardFieldError = {};
            }

            for (let fieldToValidate of FIELDS_VALIDATORS) {
                const fieldKey = fieldToValidate.key;
                for (let vLoop = 0 ; vLoop < fieldToValidate.validators.length ; ++vLoop) {
                    const validator = fieldToValidate.validators[vLoop];
                    if (!validator.test(fieldsData[fieldKey])) {
                        output.push(fieldKey);
                        if (lodashGet(config, 'setErrors')) {
                            fieldErrors[fieldKey] = validator.errorMessage;
                        }
                        vLoop = fieldToValidate.validators.length; // no need to check more validators
                    }
                }
            }
            if (paymentType == 'card') {
                if (!cardComplete.cardNumber) {
                    output.push('cardNumber');
                    if (lodashGet(config, 'setErrors')) {
                        cardFieldError.cardNumber = true;
                    }
                }
                if (!cardComplete.cardExpiry) {
                    output.push('cardExpiry');
                    if (lodashGet(config, 'setErrors')) {
                        cardFieldError.cardExpiry = true;
                    }
                }
                if (!cardComplete.cardCvc) {
                    output.push('cardCvc');
                    if (lodashGet(config, 'setErrors')) {
                        cardFieldError.cardCvc = true;
                    }
                }
            }

            if (lodashGet(config, 'setErrors')) {
                if (paymentType == 'card') {
                    setCardFieldError(cardFieldError);
                }
                setFieldErrors(fieldErrors);
            }
            return output;
        }

        function validateSingleField() {
            const matchedValidatorObj = FIELDS_VALIDATORS.find((item) => item.key == this.key);
            if (matchedValidatorObj) {
                for (let vLoop = 0 ; vLoop < matchedValidatorObj.validators.length ; ++vLoop) {
                    const validator = matchedValidatorObj.validators[vLoop];
                    if (!validator.test(fieldsData[this.key])) {
                        setFieldErrors({ ...fieldErrors, [this.key]: validator.errorMessage });
                        return;
                    }
                }
            }
        }

        function isLoading() {
            for (let key in loaders) {
                if (loaders[key]) {
                    return true;
                }
            }
            return false;
        }
    }
}

const RegisterStagePayStyle = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  .form-container {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 25px;
    text-align: center;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      width: 95%;
    }
  }
  .column-container {
    width: 100%;
  }
  .field-component {
    margin-top: 25px;
    min-width: 270px;
    html.rtl & {
      text-align: right;
    }
    html.ltr & {
      text-align: left;
    }
  }
  .payment-type-container {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      text-align: center !important;
    }
    html.rtl & {
      text-align: right;
    }
    html.ltr & {
      text-align: left;
    }
  }
  .payment-type-inner-container {
    display: inline-block;
  }
  .payment-type-control-root-component {
    html.rtl & {
      margin-right: -5px;
    }
  }
  .payment-type-image {
    max-height: 30px;
  }
  .payment-type-text {
    position: relative;
    top: -5px;
    &.card {
      padding-right: 8px;
      html.ltr & {
        padding-right: 0;
        padding-left: 12px;
      }
    }
    &.paypal {
      padding-right: 25px;
      html.ltr & {
        padding-right: 0;
        padding-left: 29px;
      }
    }
  }
  .billing-card-details-container {
    &.invisible {
      display: none;
    }
    html.rtl & {
      text-align: right;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        text-align: center;
      }
    }
    html.ltr & {
      text-align: left;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        text-align: center;
      }
    }
  }
  .card-number-container {
    margin-top: 48px;
  }
  .field-title {
    display: inline-block;
    margin-bottom: 5px;
    width: 250px;
    html.rtl & {
      text-align: right;
    }
    html.ltr & {
      text-align: left;
    }
  }
  .card-number-element, .card-expiry-element, .card-cvc-element {
    display: inline-block;
    border-bottom: 1px solid black;
    min-width: 250px;
    margin-bottom: 25px;
    &.with-error {
      border-width: 2px;
      border-color: red;
    }
  }
  .paypal-billing-details-container {
    &.invisible {
      display: none;
    }
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      text-align: center !important;
    }
    html.rtl & {
      text-align: right;
    }
    html.ltr & {
      text-align: left;
    }
  }
  .paypal-billing-details-inner-container {
    display: inline-block;
  }
  .billing-address-details-container {
    html.rtl & {
      text-align: right;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        text-align: center;
      }
    }
    html.ltr & {
      text-align: left;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        text-align: center;
      }
    }
  }
  .address-same-as-billing-component {
    html.rtl & {
      margin-right: -10px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        margin-right: -45px;
      }
    }
  }
  .field-component.billing-address {
    html.rtl & {
      margin-left: 15px;
    }
    html.ltr & {
      margin-right: 15px;
    }
  }
  .pay-now-button-container {
    margin-top: 10px;
    text-align: center;
  }
  .pay-now-button {
    width: 170px;
  }
  .error-container {
    margin-top: 15px;
    color: ${CSS_COLORS.FIELD_WARNING};
    font-size: 20px;
  }
`;

const RegisterStagePay = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        individualKey: lodashGet(state, 'registerIndividualStagesData.key'),
        stage2Data: lodashGet(state, 'registerIndividualStagesData.stage2Data'),
        stage3Data: lodashGet(state, 'registerIndividualStagesData.stage3Data'),
        stage4Data: lodashGet(state, 'registerIndividualStagesData.stage4Data'),
        stage5Data: lodashGet(state, 'registerIndividualStagesData.stage5Data'),
          registerIndividualStagesData: lodashGet(state, 'registerIndividualStagesData')
    }),
    {})(RegisterStagePayComponent);




    function clearNumber(value = "") {
      return value.replace(/\D+/g, "");
    }

    export function formatCreditCardNumber(value) {
      if (!value) {
        return value;
      }

      const issuer = Payment.fns.cardType(value);
      const clearValue = clearNumber(value);
      let nextValue;

      switch (issuer) {
        case "amex":
          nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
            4,
            10
          )} ${clearValue.slice(10, 15)}`;
          break;
        case "dinersclub":
          nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
            4,
            10
          )} ${clearValue.slice(10, 14)}`;
          break;
        default:
          nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
            4,
            8
          )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
          break;
      }

      return nextValue.trim();
    }

    export function formatCVC(value, prevValue, allValues = {}) {
      const clearValue = clearNumber(value);
      let maxLength = 4;

      if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === "amex" ? 4 : 3;
      }

      return clearValue.slice(0, maxLength);
    }

    export function formatExpirationDate(value) {
      const clearValue = clearNumber(value);

      if (clearValue.length==4)
      {
        if (clearValue.substr(2,2)[1]>40 || clearValue.substr(2,2)<24)
        {
              return `${clearValue.slice(0, 2)}`
        }
      }
      if (clearValue=="00" )
            return "";
      if (clearValue<10 && clearValue>1)
            return "0"+ parseInt(clearValue);
      if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
      }

      return clearValue;
    }

    export function formatFormData(data) {
      return Object.keys(data).map(d => `${d}: ${data[d]}`);
    }



    const StyleZenPayment = styled.div`

    input::-webkit-input-placeholder {
    text-align: center;
    }
    .inputStyle {
                box-shadow: inset #abacaf 0 0 0 2px;
                border: 0;
                background: rgba(0, 0, 0, 0);
                appearance: none;
                width: 100%;
                position: relative;
                border-radius: 3px;
                padding: 9px 12px;
                line-height: 1.4;
                color: rgb(0, 0, 0);
                font-size: 16px;
                font-weight: 400;
                height: 40px;
                max-width:250px;
                transition: all .2s ease;
                :hover{
                    box-shadow: 0 0 0 0 #fff inset, #1de9b6 0 0 0 2px;
                }
                :focus{
                    background: #fff;
                    outline: 0;
                    box-shadow: 0 0 0 0 #fff inset, #1de9b6 0 0 0 3px;
                        }
            }

    .payStyle {

                        display: inline-block;
                        outline: none;
                        cursor: pointer;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 600;
                        border-radius: 8px;
                        padding: 14px 24px;
                        border: none;
                        transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
                        background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
                        color: #fff;

    }

    .payStyleDisabled {

                        display: inline-block;
                        outline: none;
                        cursor: pointer;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 600;
                        border-radius: 8px;
                        padding: 14px 24px;
                        border: none;
                        transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
                        background: grey;
                        color: #fff;

    }



    `;
export default RegisterStagePay;
